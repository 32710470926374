import React, { useContext } from 'react';

import { CheckoutContext } from './context';
import AuthUserContext from '../Session/context';

import {
  checkoutFields,
  saveField,
  checkoutTerms,
  emailField,
  accountFields,
} from './data';

import Portal from '../Portal';
import Fields from '../Form/fields';
import Payment from './payments';

import Icons from './icons';
import Loader from '../../images/icon-loader-dark.svg';

import { TitleColour } from '../../utils/variables';
import { HeadingPlaceholder } from './placeholders';

import './form.css';

import loadComponents from '../Loadable';

const H5 = loadComponents('h5');

export default function CheckoutForm() {
  const authUser = useContext(AuthUserContext);
  const { processOrder, checkout } = useContext(CheckoutContext);
  const { processing, shipping } = checkout;
  return (
    <form
      name="checkout"
      method="POST"
      onSubmit={processOrder}
      className="checkoutForm"
      // className={`checkoutForm${!shipping && ` disable`}`}
    >
      {processing && (
        <Portal portal="lightbox">
          <div style={overlayStyle}>
            <img
              src={Loader}
              height="100px"
              width="100px"
              style={{ marginBottom: `1.875rem` }}
              alt="Processing order..."
            />
            <p style={{ textAlign: `center` }}>
              <strong style={{ fontSize: `1.75rem`, color: TitleColour }}>
                Processing order...
              </strong>
              <br />
              <strong>Warning:</strong> Do not close or refresh the page until
              your order has been confirmed.
            </p>
          </div>
        </Portal>
      )}
      {/* {!shipping && <Icons.ShippingIcon />} */}
      <H5
        style={{ textTransform: `uppercase` }}
        fallback={<HeadingPlaceholder />}
      >
        Shipping <span>Address</span>
      </H5>
      <div
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          margin: `0 -0.625rem`,
        }}
      >
        <Fields data={emailField} />
        {!authUser && <Fields data={accountFields} />}
        <Fields data={checkoutFields} />
        {authUser && !authUser.address && <Fields data={saveField} />}
        <Fields data={checkoutTerms} />
      </div>
      <Payment />
    </form>
  );
}

const overlayStyle = {
  backgroundColor: `rgba(255,255,255,0.8)`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  position: `fixed`,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 9999,
  flexDirection: `column`,
};
