export const emailField = [
  {
    type: 'input',
    inputType: 'email',
    width: '100%',
    name: 'Email Address',
    required: true,
  },
];

const accountCondition = [
  {
    field: 'create_account',
    operator: 'eq',
    value: true,
  },
];

export const accountFields = [
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Create Account',
    label: 'Would you like to create a trade account?',
  },
  {
    type: 'input',
    inputType: 'company_number',
    width: '100%',
    name: 'Company Number',
    required: true,
    condition: accountCondition,
  },
  {
    type: 'input',
    inputType: 'password',
    width: '100%',
    name: 'Password',
    required: true,
    condition: accountCondition,
  },
];

export const checkoutFields = [
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'First Name',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'Last Name',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '100%',
    name: 'Street Address',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'Town / City',
    id: 'city',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'County',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'Post Code',
    required: true,
  },
  {
    type: 'input',
    inputType: 'text',
    width: '50%',
    name: 'Phone Number',
    required: true,
  },
];

export const saveField = [
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Save Address',
    label: 'Would you like to save this address to checkout faster next time?',
    // required: true,
  },
];

export const checkoutTerms = [
  {
    type: 'input',
    inputType: 'checkbox',
    width: '100%',
    name: 'Terms Conditions',
    label:
      'By placing this order I agree to the website <a href="/terms-conditions/">Terms & Conditions</a> and consent to my personal information being collected in order to complete this transaction. For more details on how your personal information is stored please review our <a href="/privacy-policy/">Privacy Policy</a>.',
    required: true,
  },
];
