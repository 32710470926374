import React from 'react';
import { MainColour } from '../../utils/variables';

const ShippingIcon = () => {
  return (
    <div style={iconStyles}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style={{ flex: `0 0 100%` }}
      >
        <path
          d="M22.566 4H11.934C11.1244 4.01966 10.4832 4.69037 10.5 5.5V15.277C10.5 15.362 10.5433 15.4412 10.615 15.487C11.4057 15.9903 11.9149 16.8348 11.991 17.769C12.0014 17.899 12.1096 17.9994 12.24 18H15.76C15.8906 17.9999 15.9991 17.8992 16.009 17.769C16.1303 16.2069 17.4332 15.0012 19 15.0012C20.5668 15.0012 21.8697 16.2069 21.991 17.769C22.0014 17.899 22.1096 17.9994 22.24 18H23.5C23.7761 18 24 17.7761 24 17.5V5.5C24.0168 4.69037 23.3756 4.01966 22.566 4Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 18.5C0.895432 18.5 0 17.6046 0 16.5V11.5C0.00330734 8.73993 2.23995 6.5033 5 6.5H8C8.55228 6.5 9 6.94769 9 7.5V14.76C8.99937 14.8904 8.89899 14.9986 8.769 15.009C7.20643 15.1297 6.00005 16.4328 6 18C6 18.076 6.005 18.151 6.012 18.225C6.01875 18.2953 5.99559 18.3651 5.94821 18.4175C5.90083 18.4698 5.8336 18.4998 5.763 18.5H2ZM6.75 8.5H5C3.44876 8.49976 2.15316 9.68219 2.012 11.227C2.00553 11.297 2.02885 11.3665 2.07623 11.4185C2.12362 11.4704 2.19069 11.5 2.261 11.5H6.75C6.88807 11.5 7 11.3881 7 11.25V8.75C7 8.61194 6.88807 8.5 6.75 8.5Z"
          fill="white"
        />
        <path
          d="M9 20C10.1046 20 11 19.1046 11 18C11 16.8954 10.1046 16 9 16C7.89543 16 7 16.8954 7 18C7 19.1046 7.89543 20 9 20Z"
          fill="white"
        />
        <path
          d="M19 20C20.1046 20 21 19.1046 21 18C21 16.8954 20.1046 16 19 16C17.8954 16 17 16.8954 17 18C17 19.1046 17.8954 20 19 20Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default { ShippingIcon };

const iconStyles = {
  display: `inline-flex`,
  alignItems: `center`,
  justifyContent: `center`,
  width: `160px`,
  height: `160px`,
  borderRadius: `160px`,
  lineHeight: 0,
  backgroundColor: MainColour,
  position: `absolute`,
  top: `38%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
  zIndex: 60,
  padding: `1.875rem`,
};
