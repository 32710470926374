import React from 'react';
import ReactDOM from 'react-dom';

export default class Portal extends React.Component {
  constructor() {
    super();
    this.el =
      typeof document !== `undefined` ? document.createElement('div') : null;
  }

  componentDidMount = () => {
    if (typeof document !== `undefined`) {
      const { portal } = this.props;
      document.getElementById(portal).prepend(this.el);
    }
  };

  componentWillUnmount = () => {
    const { portal, description } = this.props;
    if (!description) {
      if (typeof document !== `undefined`) {
        document.getElementById(portal).removeChild(this.el);
      }
    }
  };

  render() {
    const { children } = this.props;
    return this.el ? ReactDOM.createPortal(children, this.el) : null;
  }
}
